<template>
  <b-row>
    <b-col lg="3" xl="3" md="4" sm="6" v-for="(certificate, index) in certificates" :key="index" :data="certificate" class="books">
      <!-- card 1 -->
      <b-card
          :img-src="`https://edu.startupchoikhona.tj/backend${certificate.path}`"
          :img-alt="certificate.name"
          img-top
          no-body
      >
        <b-card-body>
          <small v-if="certificate.course">
            Наименование курса:
          </small>
          <b-card-title v-if="certificate.course">
            {{certificate.course.title}}
          </b-card-title>
          <small v-if="certificate.module">
            Наименование модула:
          </small>
          <b-card-text v-if="certificate.module">
            {{certificate.module.name}}
          </b-card-text>

        </b-card-body>
        <b-card-footer class="m-0 p-0">
          <b-button-group class="w-100 m-0 p-0">
            <b-button :variant="'gradient-primary'" class="w-50" @click="showCertificate(`https://edu.startupchoikhona.tj/backend${certificate.path}`)">
              Просмотреть
            </b-button>
            <a class="w-50 btn btn-gradient-info" :href="`https://edu.startupchoikhona.tj/backend${certificate.path}`" download>
              Скачать
            </a>

          </b-button-group>
        </b-card-footer>
      </b-card>
    </b-col>
    <b-modal
        id="modal-lg"
        size="lg"
        centered
        ok-only
        ok-title="Закрыть"
        v-model="modalShow"
    >
      <b-img :src="activeCertificatePath" class="w-100"></b-img>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BCardGroup,
  BCardTitle,
  BCardFooter,
  BCardBody,
  BButton,
  BButtonGroup,
  BLink,
  BModal,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BCardGroup,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BButton,
    BButtonGroup,
    BLink,
    BModal,
    BImg,
  },
  data() {
    return {
      certificates: [],
      modalShow: false,
      activeCertificatePath: '',
    }
  },
  methods: {
    getBooks() {
      this.$http.post('https://edu.startupchoikhona.tj/backend/api/student/certificate/index', {
        length: -1,
      })
        .then(response => {
          this.certificates = response.data.data.items
        })
        .catch()
    },
    showCertificate(path) {
      this.activeCertificatePath = path
      this.modalShow = true
    },
  },
  created() {
    this.getBooks()
  },
}
</script>

<style scoped>
.books img {
  height: 200px;
  object-fit: cover;
}
</style>
